<template>
    <div>
        <h1>Data in Console</h1>
    </div>
</template>

<script>
// import axios from 'axios';

export default {
    name: "EmployeeList",
    // mounted() {
    //     axios.get('http://127.0.0.1:8000/api/students') // Use axios directly
    //     .then((resp)=>{
    //         console.warn(resp); 
    //     });
    // }

    methods: {
        async fetchBrandShow() {
            try {
            const response = await fetch('http://127.0.0.1:8000/api/students');
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            this.BrandShow = data;
            console.log(this.BrandShow);
            } catch (error) {
            console.error('Error fetching data for edit:', error);
            }
        }
    },

    mounted(){
      this.fetchBrandShow();
    },
}
</script>
